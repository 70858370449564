import { Organization } from '@mapped/rivet/dist/mapped/icons'
import { LogoutOutlined } from '@mapped/rivet/dist/mui/icons'
import {
  Button,
  FormControl,
  GlobalStyles,
  LinearProgress,
  MenuItem,
  Select,
  Tooltip,
} from '@mapped/rivet/dist/mui/material'
import type { SxProps, Theme } from '@mapped/rivet/dist/mui/styles'
import { Org } from '@mapped/schema-graph-react-apollo'
import axios from 'axios'
import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import {
  exchangeToken,
  getSessionAuthToken,
  logout,
  setAuthToken,
  setRefreshToken,
  setSessionAuthToken,
  setSessionRefreshToken,
} from '../../auth/auth0'
import { UserContext } from '../../contexts/user'
import { useModals } from '../../hooks/useModals'
import { Services } from '../../services'
import { AuthenticationModal } from './authenticationModal'

export const OrgSwitcher: FunctionComponent<IOrgSwitcherProps> = ({
  sx,
  fallbackToLoginButton,
  onLoginButtonClick,
}) => {
  const modals = useModals()
  const [ready, setReady] = useState(false)
  const { authToken, organization } = useContext(UserContext)
  const [isSwitching, setIsSwitching] = useState(false)
  const [selectedOrg, setSelectedOrg] = useState(organization?.id)
  const [isLoadingOrgs, orgs] = useOrgs()

  const isLoading = isSwitching || isLoadingOrgs

  useEffect(() => {
    setReady(true)
  }, [])

  useEffect(() => {
    setSelectedOrg(organization?.id)
  }, [organization])

  async function switchOrg(org_id: string) {
    setIsSwitching(true)

    const exchange = await exchangeToken(authToken!, org_id)

    setAuthToken(exchange.access_token!)
    setRefreshToken(exchange.refresh_token!)
    setSessionAuthToken(exchange.access_token!)
    setSessionRefreshToken(exchange.refresh_token!)

    window.location.reload()
  }

  if (!ready) {
    return null
  }

  if (fallbackToLoginButton && !authToken) {
    return (
      <Button
        className="sign-in-button"
        size="medium"
        variant="outlined"
        sx={{ minWidth: 100 }}
        onClick={() => {
          onLoginButtonClick?.()
          modals.open(AuthenticationModal)
        }}
      >
        Sign in
      </Button>
    )
  }

  if (!selectedOrg) {
    return null
  }

  return (
    <FormControl
      size="small"
      disabled={isLoading || orgs?.length < 2}
      sx={sx}
      className="org-switcher"
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Select
        size="small"
        color="primary"
        variant="outlined"
        value={selectedOrg}
        onChange={(e) => {
          const orgId = e.target.value as string
          setSelectedOrg(orgId)
          switchOrg(orgId)
        }}
        sx={{
          flex: 1,
          '.MuiSelect-select': {
            span: {
              position: 'relative',
              top: '2px',
            },
          },
        }}
      >
        {!orgs?.length && (
          <MenuItem
            value={organization?.id!}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <OrgItem org={organization!} />
          </MenuItem>
        )}

        {orgs.map((org: any) => (
          <MenuItem
            key={org.id}
            value={org.id}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <OrgItem org={org} />
          </MenuItem>
        ))}
      </Select>

      {isLoading && (
        <LinearProgress
          sx={{
            position: 'absolute',
            left: '1px',
            right: '48px',
            bottom: '0.5px',
            height: '0.8px',
            borderRadius: '0.5px',
            backgroundColor: 'transparent',

            '.MuiLinearProgress-bar': {
              backgroundColor: '#8C8F9580',
            },
          }}
        />
      )}

      {isSwitching && (
        <GlobalStyles
          styles={{
            'html, body, *': {
              pointerEvents: 'none !important' as any,
              userSelect: 'none !important' as any,
            },
          }}
        />
      )}

      <Tooltip title="Sign out" placement="bottom">
        <LogoutOutlined
          className="logout"
          onClick={() => {
            logout()
            window.location.href = '/'
          }}
          color="error"
          sx={{
            width: 'auto',
            height: 37,
            marginLeft: '10px',
            cursor: 'pointer',
            marginRight: 0,
            ':hover': { opacity: 0.7 },
          }}
        />
      </Tooltip>
    </FormControl>
  )
}

const OrgItem: FunctionComponent<{ org: Org }> = ({ org }) => {
  return (
    <>
      <Organization style={{ width: '16px', marginRight: '12px' }} />

      <span style={{ marginRight: '5px' }}>
        {org.name}
        {org.id === Services.sandbox.org_id && ' (Sandbox)'}
      </span>
    </>
  )
}

export function useOrgs(): [boolean, Org[]] {
  const orgsQuery = useQuery(
    'orgs',

    async () => {
      const res = await axios.get('/api/orgs', {
        headers: { Authorization: getSessionAuthToken() },
      })

      if (!res?.data?.orgs) {
        throw new Error("Couldn't fetch orgs")
      }

      return res?.data
    },

    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: 10,
      enabled: !!getSessionAuthToken(),
    }
  )

  return [orgsQuery.isLoading, orgsQuery?.data?.orgs || []]
}

interface IOrgSwitcherProps {
  sx?: SxProps<Theme>
  fallbackToLoginButton?: boolean
  onLoginButtonClick?: () => void
}
